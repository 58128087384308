<template>
  <v-container fluid>
    <v-card>
        <v-card-title>Download Product Types</v-card-title>
        <v-card-text>
        <v-btn
          color="success"
          class="mr-4"
          @click="downloadProductTypes(false)"
        >
          Download
        </v-btn>
        </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ProductTypes from '@/api/productTypes'
import { mapState } from 'vuex'
import xlsx from 'xlsx'

export default {
  components: {},
  async created () {},
  computed: mapState({
  }),
  data () {
    return {
      startFrom: 1,
      selectedArtist: 'All'
    }
  },
  watch: {},
  methods: {
    downloadProductTypes () {
      ProductTypes.loadProductTypesForExport().then(productTypes => {
        const preparedData = productTypes.data.map((item) => {
          delete item._id
          delete item.id
          delete item.createdAt
          delete item.updatedAt
          delete item.__v
          return { ...item }
        })
        const workbook = xlsx.utils.book_new()
        const worksheet = xlsx.utils.json_to_sheet(preparedData)
        xlsx.utils.book_append_sheet(workbook, worksheet, 'ProducTypes')
        xlsx.writeFile(workbook, 'DORICMOR-PRODUCT-TYPES.xlsx')
      })
    },
    reset () {}
  }
}
</script>
